/**
 * Returns the URL of the small thumbnail given the URL of the original.
 *
 * @param  {string} URL of the original image
 *
 * @return {string} URL of the small thumbnail image
 */
export default function(file) {
    const s = file.split('.');
    const extension = s.pop();

    return `${s.join('.')}_smallThumb.${extension}`;
}
