/* eslint-disable jsx-a11y/anchor-is-valid */
import AUCKLAND_UNLIMITED_LOGO_FOOTER from 'static/images/logos/tataki-auckland-unlimited-navy.svg';
import AUCKLAND_UNLIMITED_LOGO_HEADER from 'static/images/logos/tataki-auckland-unlimited-a-navy-rgb.svg';
import Button from 'app/components/content-blocks/button';
import CallToAction from 'app/components/content-blocks/call-to-action';
import ContentSet from 'app/components/content-blocks/content-set';
import Faq from 'app/components/content-blocks/faq';
import FileDownload from 'app/components/content-blocks/file-download';
import Flexi from 'app/components/content-blocks/flexi';
import Helmet from 'react-helmet';
import HeroCarousel from 'app/components/partials/hero-carousel';
import Icon from 'app/components/partials/icon';
import ImageCarousel from 'app/components/content-blocks/image-carousel';
import ImageGallery from 'app/components/content-blocks/image-gallery';
import { Link } from 'react-router-dom';
import Loader from 'app/components/partials/loader';
import MOCK_DATA from 'config/mock-data';
import PagebreakQuote from 'app/components/content-blocks/pagebreak-quote';
import PageHeader from 'app/components/partials/page-header';
import Section from 'app/components/partials/style-guide-section';
import Swatch from 'app/components/partials/swatch';
import Table from 'app/components/content-blocks/table';
import TextContent from 'app/components/content-blocks/text-content';
import Video from 'app/components/content-blocks/video';
import React, { Component } from 'react';

const pageMeta = {
    title: 'Style Guide',
    introduction: 'A working site style guide built to manage design and code quality control.',
    images: null
};

class StyleGuidePage extends Component {
    renderImageSpecs(label) {
        return <p className="style-guide-specs || heading-6">{label}</p>;
    }

    renderGalleryObservation() {
        return <p className="style-guide-specs image-gallery || heading-6">This component only works with even number of images (2/4/6..)</p>;
    }

    render() {
        return (
            <main className="page" role="main">
                <Helmet>
                    <title>{pageMeta.title}</title>
                    <meta name="description" content={pageMeta.introduction} />
                    <meta name="keywords" content="" />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>

                <PageHeader {...pageMeta} />

                <Section title="Colours" type="constrain">
                    <div className="style-guide-swatches">
                        {MOCK_DATA.SWATCH.items.map((swatch, index) => <Swatch key={index} {...swatch} />)}
                    </div>
                </Section>

                <Section title="Typography" type="constrain">
                    <div className="rte-content">
                        <h1>Heading 1</h1>
                        <h2>Heading 2</h2>
                        <h3>Heading 3</h3>
                        <h4>Heading 4</h4>
                        <h5>Heading 5</h5>
                        <h6>Heading 6</h6>

                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                        <p>Ut wisi enim ad minim veniam, quis nostrud <strong>exerci tation ullamcorper suscipit</strong> lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit <em>in vulputate velit esse molestie</em> consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Nam liber tempor cum soluta nobis eleifend option congue.</p>
                        <p>Nihil imperdiet doming id <a href="#" title="Link">quod mazim placerat</a> facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                    </div>
                </Section>

                <Section title="Icons" type="constrain">
                    <br />
                    <div className="style-guide-icons">
                        {[
                            'arrow-right',
                            'download',
                            'chevron',
                            'pointer',
                            'plus'
                        ].map((icon, index) => {
                            return <Icon name={icon} key={index} />;
                        })}
                    </div>
                </Section>

                <Section title="Lists" type="constrain">
                    <h4 className="style-guide-section-subtitle">Unordered</h4>
                    <ul>
                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                    </ul>

                    <h4 className="style-guide-section-subtitle">Ordered</h4>
                    <ol>
                        <li>Duis autem vel eum <strong>iriure dolor in hendrerit</strong> in vulputate velit esse molestie consequat.</li>
                        <li>Vel illum dolore eu <em>feugiat nulla</em> facilisis at vero eros et accumsan et iusto odio dignissim qui blandit.</li>
                        <li>Praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</li>
                        <li>Nimperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus <a href="#">legentis in iis qui facit</a> eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius.</li>
                    </ol>
                </Section>

                <Section title="Lines" type="constrain">
                    <h4 className="style-guide-section-subtitle">Default</h4>
                    <hr/>

                    <h4 className="style-guide-section-subtitle">Small</h4>
                    <hr className="small" />
                </Section>

                <Section title="Buttons" type="constrain">
                    <h4 className="style-guide-section-subtitle">Default</h4>
                    <div role="group" className="style-guide-button-group">
                        <Link className="button" to="/">Default</Link>
                        <Link className="button is-active" to="/">Active</Link>
                        <Link className="button is-disabled" to="/">Disabled</Link>
                    </div>

                    <h4 className="style-guide-section-subtitle">Alternative</h4>
                    <div role="group" className="style-guide-button-group">
                        <Link className="button alternative" to="/">Default</Link>
                        <Link className="button alternative is-active" to="/">Active</Link>
                        <Link className="button alternative is-disabled" to="/">Disabled</Link>
                    </div>

                    {/* Block Button */}
                    <h4 className="style-guide-section-subtitle">Block Button</h4>
                    <div role="group" className="style-guide-button-group">
                        <button className="block-button">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" className="icon reverse" title="Navigate left" />
                            </span>
                        </button>

                        <button className="block-button">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" title="Navigate right" />
                            </span>
                        </button>

                        <button className="block-button">
                            <span className="block-button-inner">
                                <span className="block-button-label">Example button with label left</span>
                                <Icon name="arrow-right" />
                            </span>
                        </button>

                        <button className="block-button">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" className="icon reverse" />
                                <span className="block-button-label">Example button with label right</span>
                            </span>
                        </button>
                    </div>

                    {/* Block Button */}
                    <h4 className="style-guide-section-subtitle">Block Button: Overlay</h4>
                    <div role="group" className="style-guide-button-group" style={{ backgroundImage: 'url(https://unsplash.it/1000/100?random)' }}>
                        <button className="block-button overlay">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" className="icon reverse" title="Navigate left" />
                            </span>
                        </button>

                        <button className="block-button overlay">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" title="Navigate right" />
                            </span>
                        </button>

                        <button className="block-button overlay">
                            <span className="block-button-inner">
                                <span className="block-button-label">Example button with label left</span>
                                <Icon name="arrow-right" />
                            </span>
                        </button>

                        <button className="block-button overlay">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" className="icon reverse" />
                                <span className="block-button-label">Example button with label right</span>
                            </span>
                        </button>
                    </div>

                    {/* Block Button */}
                    <h4 className="style-guide-section-subtitle">Block Button: Overlay Dark</h4>
                    <div role="group" className="style-guide-button-group" style={{ backgroundImage: 'url(https://unsplash.it/1000/100?random)' }}>
                        <button className="block-button overlay-dark">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" className="icon reverse" title="Navigate left" />
                            </span>
                        </button>

                        <button className="block-button overlay-dark">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" title="Navigate right" />
                            </span>
                        </button>

                        <button className="block-button overlay-dark">
                            <span className="block-button-inner">
                                <span className="block-button-label">Example button with label left</span>
                                <Icon name="arrow-right" />
                            </span>
                        </button>

                        <button className="block-button overlay-dark">
                            <span className="block-button-inner">
                                <Icon name="arrow-right" className="icon reverse" />
                                <span className="block-button-label">Example button with label right</span>
                            </span>
                        </button>
                    </div>

                    {/* Text Link */}
                    <h4 className="style-guide-section-subtitle">Text Link</h4>
                    <div role="group" className="style-guide-button-group">
                        <Link className="text-link" to="/">
                            Read more: Default
                            <Icon name="pointer" width="30" height="20" viewBox="0 0 30 20" />
                        </Link>
                        <Link className="text-link large" to="/">
                            Read more: Large
                            <Icon name="pointer" width="30" height="20" viewBox="0 0 30 20" />
                        </Link>
                    </div>
                </Section>

                <Section title="Pagination" type="constrain">
                    <h4 className="style-guide-section-subtitle">Carousel</h4>
                    <div className="">
                        {/* Pagination */}
                        <div className="carousel-pagination" style={{ backgroundImage: 'url(https://unsplash.it/1000/100?random)' }}>
                            <button className="carousel-pagination-item is-active">
                                <span className="carousel-pagination-item-title">Go to slide 1</span>
                            </button>
                            <button className="carousel-pagination-item">
                                <span className="carousel-pagination-item-title">Go to slide 2</span>
                            </button>
                            <button className="carousel-pagination-item">
                                <span className="carousel-pagination-item-title">Go to slide 3</span>
                            </button>
                            <button className="carousel-pagination-item">
                                <span className="carousel-pagination-item-title">Go to slide 4</span>
                            </button>
                        </div>
                    </div>
                    <br/>
                </Section>

                <Section title="Blockquote" type="constrain">
                    <blockquote>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.</p>
                        <em>Author, Company name</em>
                    </blockquote>
                </Section>

                <Section title="Logos" type="constrain">
                    <div className="style-guide-logo">
                        <img src={AUCKLAND_UNLIMITED_LOGO_HEADER} alt="Tātaki Auckland Unlimited"/>
                    </div>
                    <div className="style-guide-logo">
                        <img src={AUCKLAND_UNLIMITED_LOGO_FOOTER} alt="Tātaki Auckland Unlimited"/>
                    </div>
                </Section>

                <Section title="Constrain Widths" type="constrain">
                    <p className="style-guide-line || constrain-width no-pad x-large">X Large</p>
                    <p className="style-guide-line || constrain-width no-pad large">Large</p>
                    <p className="style-guide-line || constrain-width no-pad">Default</p>
                    <p className="style-guide-line || constrain-width no-pad medium">Medium</p>
                    <p className="style-guide-line || constrain-width no-pad small">Small</p>
                </Section>

                <Section title="Hero Carousel">
                    <HeroCarousel {...MOCK_DATA.HERO_CAROUSEL} />
                    {this.renderImageSpecs('Image Size: 2460px by 1350px')}
                </Section>

                <Section title="Image Carousel">
                    <ImageCarousel {...MOCK_DATA.IMAGE_CAROUSEL} />
                    {this.renderImageSpecs('Images Size: 1133px by 638px')}
                    {this.renderImageSpecs('Max Size: 500kb')}
                </Section>

                <Section title="Image Gallery: Single">
                    {this.renderGalleryObservation()}
                    <ImageGallery {...MOCK_DATA.IMAGE_GALLERY_SINGLE} />
                    {this.renderImageSpecs('Both Images Size(s): 800px by 600px')}
                    {this.renderImageSpecs('Max Size: 500kb')}
                </Section>

                <Section title="Image Gallery: Multiple">
                    {this.renderGalleryObservation()}
                    <ImageGallery {...MOCK_DATA.IMAGE_GALLERY_MULTIPLE} />
                    {this.renderImageSpecs('Both Images Size(s): 800px by 600px')}
                    {this.renderImageSpecs('Max Size: 500kb')}
                </Section>

                <Section title="Video">
                    <Video {...MOCK_DATA.VIDEO} />
                </Section>

                <Section title="Text">
                    <TextContent {...MOCK_DATA.TEXT_PROPS} />
                </Section>

                <Section title="Text Aside: Two Headings">
                    <TextContent {...MOCK_DATA.TEXT_ASIDE_WITH_TITLE_PROPS} />
                </Section>

                <Section title="Text Aside: One Heading">
                    <TextContent {...MOCK_DATA.TEXT_ASIDE_PROPS} />
                </Section>

                <Section title="Text Quote">
                    <TextContent {...MOCK_DATA.TEXT_QUOTE_PROPS} />
                </Section>

                <Section title="Button">
                    <Button {...MOCK_DATA.BUTTON} />
                </Section>

                <Section title="Call to Action">
                    <div className="content-blocks">
                        <CallToAction {...MOCK_DATA.CALL_TO_ACTION_LEFT} />
                        <CallToAction {...MOCK_DATA.CALL_TO_ACTION_RIGHT} />
                    </div>
                    {this.renderImageSpecs('Both Images Size(s): 800px by 600px')}
                    {this.renderImageSpecs('Max Size: 500kb')}
                </Section>

                <Section title="Content Set">
                    <ContentSet {...MOCK_DATA.CONTENT_SET} />
                    {this.renderImageSpecs('All Images Size(s): 420px by 320px')}
                </Section>

                <Section title="Pagebreak quote">
                    <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE_NO_IMAGE} />
                </Section>

                <Section title="Pagebreak quote with image">
                    <div className="content-blocks">
                        <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE_LEFT} />
                        <PagebreakQuote {...MOCK_DATA.PAGEBREAK_QUOTE_RIGHT} />
                    </div>
                    {this.renderImageSpecs('Both Images Size(s): 800px by 600px')}
                    {this.renderImageSpecs('Max Size: 500kb')}
                </Section>
                <Section title="Table">
                    <div className="content-blocks">
                        <Table {...MOCK_DATA.TABLE} />
                    </div>
                </Section>
                <Section title="Flexi">
                    <div className="content-blocks">
                        <Flexi {...MOCK_DATA.FLEXI} />
                    </div>
                </Section>
                <Section title="FAQ">
                    <Faq {...MOCK_DATA.FAQ} />
                </Section>

                <Section title="File Download">
                    <div className="content-blocks">
                        <FileDownload {...MOCK_DATA.FILE_DOWNLOAD_1} />
                        <FileDownload {...MOCK_DATA.FILE_DOWNLOAD_2} />
                        <FileDownload {...MOCK_DATA.FILE_DOWNLOAD_3} />
                    </div>
                </Section>

                <Section title="Loader" type="constrain">
                    <Loader />
                </Section>

                <Section title="Form" type="constrain">
                    <form
                        className="form"
                        noValidate={true}
                        action="#">
                        <div className="form-fieldset required">
                            <input
                                className="form-field || input is-invalid"
                                id="Field 1"
                                type="text"
                                placeholder="Placeholder description"
                                required={true}
                                aria-label="Field 1"
                                aria-required={true}
                                aria-invalid={true}
                            />
                            <span className="form-validation">
                                <Icon name="alert" />
                                This is a required field
                            </span>
                        </div>
                        <div className="form-fieldset">
                            <input
                                className="form-field || input"
                                id="Field 2"
                                type="text"
                                placeholder="Text input field"
                                required={false}
                                aria-label="Field 2"
                                aria-required={false}
                                aria-invalid={false}
                            />
                        </div>
                        <div className="form-fieldset">
                            <input
                                className="form-field || input"
                                id="Field 3"
                                type="text"
                                placeholder="Another input field"
                                required={false}
                                aria-label="Field 3"
                                aria-required={false}
                                aria-invalid={false}
                            />
                        </div>
                        <div className="form-fieldset required">
                            <textarea
                                className="form-field || input is-invalid || text-area"
                                id="Text"
                                placeholder="Text here"
                                required={true}
                                aria-label="Text"
                                aria-required={true}
                                aria-invalid={true}
                            />
                            <span className="form-validation">
                                <Icon name="alert" />
                                This is a required field
                            </span>
                        </div>
                        <button
                            className="button || form-button"
                            type="submit"
                            disabled={true}>
                            Submit
                        </button>
                    </form>
                </Section>
            </main>
        );
    }
}

export default StyleGuidePage;
